<template>
  <v-card class="pa-8">
    <header-content :pageTitle="'SFTP BNI GFX'" />
    <div class="d-flex justify-end">
      <v-btn
        color="primary"
        rounded
        class="ml-4 white--text text-none"
        @click="$router.push('/sftp-upload')"
      >
        Unggah
      </v-btn>
    </div>
    <v-data-table
      :headers="headers"
      :items="dataWebs"
      :items-per-page="10"
      @click:row="download"
      @mouseleave:row="mouseleave"
      @mouseover:row="mouseOver"
      :loading="loading"
      class="elevation-0"
    >
      <template v-slot:[`item.size`]="{ item }">
        <p>{{ item.size_string }}</p>
      </template>
      <template v-slot:[`item.name`]="{ item }">
        <div style="display: flex">
          <div class="right-links-container">
            <ul
              class="right-list-container flexbox"
              style="list-style-type: none; padding-left: 0"
            >
              <li>
                <v-avatar v-if="item.type == 'file'" size="38">
                  <v-icon class="amber" dark>mdi-clipboard-text</v-icon>
                </v-avatar>
                <v-avatar
                  v-if="item.type !== 'file'"
                  v-bind="attrs"
                  v-on="on"
                  size="38"
                >
                  <v-icon class="grey" dark>mdi-folder</v-icon>
                </v-avatar>
              </li>
              <li>
                <a
                  @mouseleave="mouseleave"
                  @mouseover="mouseOver(item)"
                  style="color: black"
                  >{{ item.name }}</a
                >
                <br />
                <a style="color: grey">{{ convertDate(item.modified_at) }}</a>
              </li>
            </ul>
          </div>
        </div>
      </template>
    </v-data-table>
    <div v-if="hover" style="position: absolute; left: 50%; top: 50%">
      <v-card :width="widthSize / 2.5">
        <v-card-title class="black--text mt-8 hoverTitle">
          <v-avatar size="56">
            <v-icon class="amber" v-if="this.hoverData.type == 'file'" dark
              >mdi-clipboard-text</v-icon
            >
            <v-icon class="grey" v-if="this.hoverData.type == 'directory'" dark
              >mdi-folder</v-icon
            >
          </v-avatar>
          <v-row
            class="ml-3"
            style="margin-right: -5px"
            justify="space-between"
          >
            <p class="ml-3">Name:</p>
            <p class="ml-5">{{ this.hoverData.name }}</p>
          </v-row>
        </v-card-title>
        <div>
          <v-row class="ml-3 mr-3" justify="space-between">
            <p class="ml-3">Type:</p>
            <p class="ml-5">{{ this.hoverData.type }}</p>
          </v-row>
          <v-row class="ml-3 mr-3" justify="space-between">
            <p class="ml-3">Size:</p>
            <p class="ml-5">{{ this.hoverData.size_string }}</p>
          </v-row>
          <v-row class="ml-3 mr-3" justify="space-between">
            <p class="ml-3">Parent Folder:</p>
            <p class="ml-5">
              {{ "/" }}
            </p>
          </v-row>
        </div>
      </v-card>
    </div>
    <popup-success
      :dialog="showSuccessDialog"
      :closeDialog="closeSuccessDialog"
    />
    <popup-error :dialog="showErrDialog" :closeDialog="closeErrDialog" />
  </v-card>
</template>

<script>
import { mapState } from "vuex";
import converters from "../../helpers/converters.js";
import store from "../../store/index.js";
import axios from "axios";
import HeaderContent from "../../components/Header/HeaderContent.vue";
import PopupError from "../../components/Popup/PopupError.vue";
import PopupSuccess from "../../components/Popup/PopupSuccess.vue";

export default {
  components: {
    HeaderContent,
    PopupError,
    PopupSuccess,
  },

  data() {
    return {
      hoverData: {},
      showErrDialog: false,
      showSuccessDialog: false,
      headers: [
        {
          text: "Name",
          align: "start",
          sortable: true,
          value: "name",
        },
        { text: "Size", value: "size", sortable: true },
      ],
    };
  },

  created() {
    store.commit("bniGfx/clearDataWeb");
    store.commit("bniGfx/setLoading", true);
    store.dispatch("bniGfx/fetchSFTPData", ["", false]);
  },

  computed: {
    ...mapState("bniGfx", ["dataWeb", "hover", "loading"]),
    dataWebs() {
      let newWeb = [];
      this.dataWeb.forEach((a) => {
        if (a.type === "folder") {
          newWeb.push(a);
        }
      });
      return newWeb;
    },
  },

  methods: {
    closeSuccessDialog() {
      this.showSuccessDialog = !this.showSuccessDialog;
    },
    closeErrDialog() {
      this.showErrDialog = !this.showErrDialog;
    },
    convertDate(date) {
      return converters.convertLocaleDate(date);
    },
    download(payload) {
      store.commit("bniGfx/setHover", false);
      store.commit("bniGfx/clearDataWeb");
      if (payload.type == "file") {
        payload.route = "";
        store.dispatch("bniGfx/downloadFileSFTP", payload).then((r) => {
          if (r) this.showErrDialog = true;
        });
      } else if (this.$route.params.id == payload.name) {
        console.log("blocked");
      } else {
        this.$router.push({ path: `/sftp/${payload.name}` });
      }
    },
    hapus(payload) {
      if (payload.type == "directory") {
        payload.route = "";
        store.dispatch("bniGfx/deleteDirectorySFTP", payload).then((r) => {
          if (r === "success") {
            this.showSuccessDialog = true;
          } else {
            this.showErrDialog = true;
          }
        });
      } else {
        payload.route = "";
        store.dispatch("bniGfx/deleteFileSFTP", payload).then((r) => {
          if (r === "success") {
            this.showSuccessDialog = true;
          } else {
            this.showErrDialog = true;
          }
        });
      }
    },
    mouseOver: function(data) {
      this.hoverData = data;
      store.commit("bniGfx/setHover", true);
    },
    mouseleave: function() {
      store.commit("bniGfx/setHover", false);
    },
  },
};
</script>
